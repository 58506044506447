.header-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 4rem;
}

.header-section h1 {
  font-size: var(--h1-font-size);
  font-weight: 100;
}

.header-section p {
  width: 75%;
  text-align: center;
  font-size: var(--paragraph-font-size);
}

.content-row {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  margin-top: 1rem;
}

.content-row div h1 {
  font-size: var(--h2-font-size);
  font-weight: 100;
}

.content-row div {
  text-align: center;
  padding: 5rem;
  width: 100%;
  font-size: var(--paragraph-font-size);
}

.content-row div:first-of-type {
  border-right: 1px solid black; /* Adjust the width and color as needed */
}

@media screen and (max-width: 768px) {
  .content-row {
    flex-direction: column;
  }

  .content-row div {
    padding-top: 0;
    padding-bottom: 0;
    padding: 0;
  }

  .content-row div:first-of-type {
    border-right: none;
  }

  .content-row div:nth-of-type(2) p:first-of-type {
    margin-top: 0;
  }

  .header-section h1 {
    font-size: var(--h1-font-size);
    text-align: center;
  }

  .header-section {
    margin-top: 2rem;
  }

  .content-row div h1 {
    font-size: var(--h2-font-size);
  }
}
