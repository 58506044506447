.questions-row {
  display: flex;
  justify-content: center;
  align-items: center;
}
.questions-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  margin: 2rem;
  width: 60%;
}

.faq-item {
  margin: 0.1rem;
  border: 0.1px solid black;
  padding: 0.5rem;
  width: 100%;
}

.faq-item:hover {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .questions-container {
    width: 100%;
    text-align: center;
    margin: 0;
  }
}
