.bg {
  margin-top: 0;
  padding-top: 0;
  background-image: url("../../assets/pixar.jpg");
  /* background-image: url("../../assets/dre_cathy.jpg"); */
  /* Full height */
  height: 100vh;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.home-container {
  display: flex;
  flex-direction: column;
}

h1 {
  margin-top: 0;
}

.secondary-font {
  color: #003636;
}

.center-page {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: black;
  /* color: white; */
}

.page-row {
  display: flex;
  justify-content: space-evenly;
  height: 100vh;
  align-items: center;
}
.page-row div {
  width: 100%;
  margin: 1rem;
  padding: 1rem;
}

.img-container {
  width: 100%;
  max-width: 100%;
  padding-bottom: 60%;
  position: relative;
  overflow: hidden;
  text-align: center;
}

.img-container img {
  max-width: 100%;
}

.key-info-section p {
  padding: 1rem;
}

@media screen and (max-width: 768px) {
  .page-row {
    flex-direction: column;
    flex-direction: column-reverse;
  }

  .page-row div {
    padding: 0;
    margin: 0;
  }

  .img-container {
    padding: 0;
    margin: 0;
    width: 100%;
  }
}

/* Homepage */
.key-info-section {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: var(--paragraph-font-size);
}

.key-info-section h1,
.key-info-section h2,
.key-info-section p {
  margin: 0; /* Remove default margin for headers */
}

.key-info-section p:first-of-type {
  margin-top: 1rem;
}

.key-info-section p:last-of-type {
  margin-bottom: 2rem;
}

.key-info li {
  list-style: none;
  padding: 1rem;
}

.btn-link {
  padding: 1rem;
  background-color: inherit;
  width: 10rem;
  border: 1px solid darkgray;
}

.btn-link:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  cursor: pointer;
}

.footer {
  /* width: 100%; */
  padding: 2rem;
  font-size: var(--paragraph-font-size);
  text-align: center;
}

#map-button {
  text-decoration: underline;
}

#map-button:hover {
  cursor: pointer;
}
