.full-screen-modal {
  position: fixed;
  z-index: 1;
  padding-top: 2rem;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-items: center;
}

.full-screen-modal img {
  max-width: 100%;
  max-height: 80vh;
  padding: 1rem;
}

.full-screen-modal button {
  background-color: transparent;
  color: white;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  padding: 0.5rem;
  margin: 0.5rem;
}

.full-screen-modal .close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

@media screen and (max-width: 768px) {
  /* Adjust styles for larger screens if needed */
  .full-screen-modal {
    padding-top: 5rem;
    flex-direction: column;
  }

  .full-screen-modal img {
    max-height: 70vh;
  }

  .small-photos img {
    min-width: 300px;
  }
}

.small-photos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.img-box {
  margin: 0.5rem;
  width: 20rem;
  height: 20rem;
}

.img-box:hover {
  cursor: pointer;
}

.small-photos img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5%;
}
