/* Navbar.css */

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: inherit;
}

.navbar-title h2 {
  margin: 0;
  text-decoration: none;
}

.navbar-links {
  overflow: hidden;
  max-height: 300px; /* Set a maximum height for the dropdown */
  opacity: 1;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.header {
  font-size: var(--paragraph-font-size);
  text-decoration: none;
}

.navbar-links ul {
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center; /* Center links horizontally */
}

.navbar-links li {
  margin: 0 15px;
}

.navbar-links a {
  text-decoration: none;
}

.burger-menu {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.bar {
  width: 25px;
  height: 3px;
  margin: 2px 0;
  background-color: rgb(81, 81, 81);
}

.navbar-links.active {
  max-height: 0;
  opacity: 0;
}

.navbar-links a.selected-page {
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .navbar-links {
    flex-direction: column;
    height: 100%;
    align-items: center;
    max-height: none;
    overflow: hidden;
    position: absolute;
    top: 60px; /* Adjust as needed */
    left: 0;
    width: 100%;
    color: #414a4c;
    background-color: var(--body-bg-color); /* Add a background color */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow for separation */
  }

  .navbar-links ul {
    margin: 10px 0;
    padding: 0; /* Reset padding */
    width: 100%; /* Take full width */
    display: flex;
    flex-direction: column;
  }

  .navbar-links li {
    margin: 0; /* Reset margin */
    padding: 10px; /* Add padding for spacing */
    /* border-bottom: 1px solid rgb(200, 200, 200); Add a border between items */
    padding: 2rem;
  }

  .navbar-links a {
    text-decoration: none;
    color: #414a4c;
  }

  .burger-menu {
    display: flex;
  }

  .navbar-links ul {
    margin: 10px 0; /* Add margin for better spacing */
  }
}
